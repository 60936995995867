import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Button from "../components/elements/button"
import OnlineShopSlider from "../components/home/online-shop-slider"
import HomeSlider from "../components/sliders/common"
import { ArrowLeftIcon, ArrowRightIcon, XIcon } from "@heroicons/react/solid"
import Fade from "react-reveal/Fade"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useState } from "react"
import InquiryForm from "../components/home/inquiry-form"
import { Zoom } from "react-reveal"

import ReactPlayer from "react-player"

const About = ({ data }) => {
  const [success, setSuccess] = useState(false)
  const [modal, setModal] = useState(false)

  const images = {
    hero2: convertToBgImage(getImage(data.hero2)),
    privateevent: convertToBgImage(getImage(data.privateevent)),

    one: convertToBgImage(getImage(data.one)),
    two: convertToBgImage(getImage(data.two)),
    three: convertToBgImage(getImage(data.three)),
    four: convertToBgImage(getImage(data.four)),
  }

  const localTalent = [
    {
      image: images.one,
    },
    {
      image: images.two,
    },
    {
      image: images.three,
    },
    {
      image: images.four,
    },
  ]

  function toastHandleChange(newValue) {
    setSuccess(newValue)
    setModal(false)
  }

  const notify = () => {
    toast.success("Thank you for your inquiry. We will get back to you soon.")
  }

  if (success) {
    notify()
    setTimeout(function () {
      setSuccess(false)
    }, 5000)
  }

  return (
    <Layout>
      <Seo title="About" />
      <section>
        <BackgroundImage
          {...images.hero2}
          className="bg-primary-brown py-72 w-full"
        >
          <Fade bottom>
            <div>
              <div className="lg:text-8xl text-7xl text-white text-center font-prata gap-6">
                About.
              </div>
            </div>
          </Fade>
        </BackgroundImage>
      </section>

      <section>
        <div className="container mx-auto lg:px-20 px-10 py-24">
          <div className="w-full flex md:grid grid-cols-3 gap-5 md:gap-12 items-center overflow-scroll">
            <div>
              <Fade>
                <StaticImage
                  src="../images/about/about-new-1.png"
                  alt="The Vault Coffeehouse"
                  className="md:w-full w-60"
                />
              </Fade>
            </div>
            <div>
              <Fade>
                <StaticImage
                  src="../images/about/about-new-2.png"
                  alt="The Vault Coffeehouse"
                  className="md:w-full w-60"
                />
              </Fade>
            </div>
            <div>
              <Fade>
                <StaticImage
                  src="../images/about/about-new-3.png"
                  alt="The Vault Coffeehouse"
                  className="md:w-full w-60"
                />
              </Fade>
            </div>
          </div>

          <div className="w-full  font-light text-base text-center mt-10  mb-8">
            Coffee & friends are the perfect blend
          </div>

          <div className="w-full  font-light text-base text-center">
            We love witnessing people bond over splendid coffee, delicious food
            and great music, and this is what  Vault Coffeehouse stands for.
            The joy these elements bring to our community and the extent to
            which they can make us appreciate ‘living in the moment’ and be
            grateful for the little things in life, are unmatched!
          </div>

          <div className="w-full mt-20 grid xl:grid-cols-2 grid-cols-1 gap-12  justify-center  items-center overflow-hidden">
            <div>
              <StaticImage
                src="../images/about/finest-coffee-about.png"
                alt="The Vault Coffeehouse"
              />
            </div>
            <div>
              <div className="font-prata xl:text-5xl text-4xl mb-8 xl:text-left text-center">
                Quality above all else
              </div>
              {/* <div className="font-light text-base  mb-8 xl:text-left text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore
              </div> */}

              <div className=" grid lg:grid-cols-3 grid-cols-1 xl:gap-x-14 gap-10 items-center ">
                <div className="flex justify-center w-full  ">
                  <StaticImage
                    src="../images/about/locally-roasted-coffee-about.png"
                    alt="Locally roasted coffee"
                    className="lg:w-full w-1/2"
                  />
                </div>
                <div className="col-span-2">
                  <div className="font-prata text-4xl	 mb-4 lg:text-left text-center">
                    Love is in the air, and it smells like coffee
                  </div>

                  <div className="font-light text-base lg:text-left text-center">
                    Quality is our first language, so we speak it fluently. We
                    are dedicated to serving only the best locally roasted
                    coffee to satisfy our coffeeholics because that is all we
                    know! How does that saying go? A fresh cup of glorious
                    coffee a day, keeps the grumpy away? Yep, sounds about
                    right!
                  </div>
                </div>

                <div className="flex justify-center w-full ">
                  <StaticImage
                    src="../images/about/Homemade-delicacies-about.png"
                    alt="Homemade delicacies"
                    className="lg:w-full w-1/2"
                  />
                </div>

                <div className="col-span-2">
                  <div className="font-prata text-4xl	 mb-4 lg:text-left text-center">
                    Homemade goodies
                  </div>

                  <div className="font-light text-base lg:text-left text-center">
                    Did someone say delicious home-made delicacies? That’s us!
                    Our passion for quality is not limited to just coffee, but
                    extends to all aspects of our services which of course means
                    you’ll be treating yourself to the best-of-the-best tasting
                    pastries and other mouth-watering treats.
                  </div>
                </div>
              </div>

              {/* <div className="flex mb-8 justify-between ">
                <div>
                  <div>
                    <StaticImage
                      src="../images/about/locally-roasted-coffee-about.png"
                      alt="Locally roasted coffee"
                    />
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="font-prata text-4xl	 mb-4 lg:text-left text-center">
                      Locally roasted coffee
                    </div>

                    <div className="font-light text-base lg:text-left text-center">
                      Serving only the best locally roasted coffee and beloved
                      community confections are the ways to a perfect day in
                      Pitcairn.
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="flex justify-between">
                <div>
                  <div>
                    <StaticImage
                      src="../images/about/locally-roasted-coffee-about.png"
                      alt="Locally roasted coffee"
                    />
                  </div>
                </div>

                <div>
                  <div className="">
                    <div className="font-prata text-4xl	 mb-4 lg:text-left text-center">
                      Homemade delicacies
                    </div>

                    <div className="font-light text-base lg:text-left text-center">
                      In our coffee house, We offer tasty & healthy homemade
                      delicacies to satisfy your taste buds. Experience the
                      authentic taste of our coffee and pastry.
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-cream-brown">
        <div className="container mx-auto lg:px-20 px-10 py-24">
          <div className="font-prata lg:text-5xl mb-8 text-center">
            Private Events
          </div>
          <div className="w-5/6  mx-auto font-light text-base text-center mb-8">
            How would you like to have an area that smells like freshly brewed
            coffee and buttered bagels all to yourself? Possibly a place with
            rich culture and live music to keep you entertained? A full menu of
            exquisite catering options for any occasion awaits you. Keep reading
            to see what we offer! Start creating beautiful memories with us
            already!
          </div>

          <div className="grid lg:grid-cols-4 grid-cols-2 justify-center gap-10 mb-10">
            <div className="lg:col-span-4 col-span-2  ">
              <ReactPlayer
                className="react-player  md:h-44 h-36	"
                url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
                width="100%"
              />
            </div>

            <div>
              <StaticImage
                src="../images/about/thumb-01-private-events-about.jpg"
                alt="Private Event"
              />
            </div>
            <div>
              <StaticImage
                src="../images/about/thumb-02-private-events-about.jpg"
                alt="Private Event"
              />
            </div>
            <div>
              <StaticImage
                src="../images/about/thumb-03-private-events-about.jpg"
                alt="Private Event"
              />
            </div>
            <div>
              <StaticImage
                src="../images/about/thumb-04-private-events-about.jpg"
                alt="Private Event"
              />
            </div>
          </div>

          <div className="bg-primary-brown  grid lg:grid-cols-5 grid-cols-4 gap-4 px-10 py-10 justify-center  items-center">
            <div className="col-span-4">
              <div className="font-prata text-black-shade text-4xl mb-4 lg:text-left text-center">
                The ultimate private gathering:
              </div>

              <ul className="text-lg list-disc  ml-6">
                <li>Space for up to 100 guests</li>
                <li>Fully customizable menu options</li>
                <li>Onsite barista & chef to prepare your food fresh</li>
                <li> Live entertainment </li>
              </ul>
            </div>

            <div className="lg:col-auto col-span-4 flex justify-center w-full">
              <div onClick={() => setModal(true)}>
                <Button filled text="Inquire Now" green />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto lg:px-20 px-10 py-24">
          <div className="font-prata  lg:text-5xl text-4xl text-center text-black mb-5">
            Discovering local talent
          </div>

          <div className="font-light  text-black text-base text-center">
            Picture this: You are here at Vault Coffeehouse with your
            friends and loved ones, sipping on a divine cuppa Joe, munching on
            some sweet n’ savory goodness, laughing, singing some karaoke, and
            listening to other wonderful live performers do their thing. Could
            life get any better? Enjoy some good old American culture, make
            memories with your people! And who knows? You just might be the next
            big thing on the billboard charts! Let us provide you with the
            opportunity to showcase your talent to a coffee-lovin’, good-time
            havin’audience!
          </div>

          <Fade bottom>
            <div className="mt-14 ">
              <div className="relative">
                <div className="swiper-button-prev-custom absolute inset-y-0 flex items-center -left-5">
                  <button className="hover:p-7 duration-200 p-5 rounded-full bg-primary-brown relative z-10">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <ArrowLeftIcon className="w-5 text-white" />
                    </div>
                  </button>
                </div>
                <HomeSlider images={localTalent} talent />
                <div className="swiper-button-next-custom absolute inset-y-0 flex items-center -right-5">
                  <button className="hover:p-7 duration-200 p-5 rounded-full bg-primary-brown relative z-10">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <ArrowRightIcon className="w-5 text-white" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="">
                <div className="my-custom-pagination-div flex mt-5 justify-center"></div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
      {modal && (
        <section>
          <div className="h-screen w-full bg-black/50 fixed top-0 z-40 p-10 overflow-y-scroll">
            <Fade bottom duration={500}>
              <div className="max-w-6xl bg-white mx-auto p-10 rounded-xl relative">
                <div className="mb-10 font-semibold">
                  Please fill the below form to send your inquiry
                </div>
                <div className="absolute top-6 right-6">
                  <XIcon
                    className="w-7 hover:rotate-90 duration-200 cursor-pointer"
                    onClick={() => setModal(false)}
                  />
                </div>

                <InquiryForm
                  success_state={success}
                  onChange={toastHandleChange}
                />
              </div>
            </Fade>
          </div>
        </section>
      )}
      {success && (
        <div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      )}
    </Layout>
  )
}

export default About

export const query = graphql`
  query aboutImagesAndAboutImages {
    hero2: file(relativePath: { eq: "pages/about/about-header.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    one: file(relativePath: { eq: "about/one.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    two: file(relativePath: { eq: "about/two.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    three: file(relativePath: { eq: "about/three.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    four: file(relativePath: { eq: "about/four.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
